import React, { useState, useEffect } from "react";
import OwlCarousel from "react-owl-carousel";
import { Link, useParams } from "react-router-dom";
import BlogHomebox from "./BlogHomebox";
import DataContent from "./DataContent";
import { Helmet } from "react-helmet";

export default function BlogDetails() {
  const Site_Url = "https://aryasamajmarriage.co.in/";
  const BASE_URL = Site_Url + "admin/API/";

  //Testimonial API
  const [clientdata, setclientdata] = useState([]);
  useEffect(() => {
    const Allclient = async () => {
      const response = await fetch(`${BASE_URL}clientAPI.php`);
      const jsonData = await response.json();
      setclientdata(jsonData);
    };
    Allclient();
  }, [BASE_URL]);

  let { slug } = useParams();
  //Blog Details
  const [Blogdetails, setBlogdetailsdetails] = useState([]);

  useEffect(() => {
    const BlogdetailsData = async () => {
      const response = await fetch(
        `${BASE_URL}blogDetailsAPI.php?slugname=${slug}`
      );
      const jsonData = await response.json();
      setBlogdetailsdetails(jsonData);
    };
    BlogdetailsData();  
  });

  //website Setting API
  const [webSetting, setwebSetting] = useState([]);
  useEffect(() => {
    const webSettingData = async () => {
      const response = await fetch(`${BASE_URL}website_settingAPI.php`);
      const jsonData = await response.json();
      setwebSetting(jsonData);
    };
    webSettingData();
  }, [BASE_URL]);

  //whychoose API
  const [whyChoosedata, setwhyChoosedata] = useState([]);
  useEffect(() => {
    const AllwhyChoose = async () => {
      const response = await fetch(`${BASE_URL}whychooseAPI.php`);
      const jsonData = await response.json();
      setwhyChoosedata(jsonData);
    };
    AllwhyChoose();
  }, [BASE_URL]);

  const [Catlang, setCatlang] = useState("");
  useEffect(() => {
    const languagecategory = Blogdetails.lang;
    setCatlang(languagecategory);
  }, [Blogdetails.lang]);

  // Blog API
  const [Blogs, setBlogsdata] = useState([]);
  useEffect(() => {
    const AllBlogs = async () => {
      const response = await fetch(
        `${BASE_URL}catblogAPI.php?langcat=${Catlang}`
      );
      const jsonData = await response.json();

      setBlogsdata(jsonData);
    };
    AllBlogs();
  }, [Catlang, BASE_URL]);

  const ClientDataSlider = {
    loop: true,
    margin: 30,
    responsiveClass: true,
    nav: false,
    dots: false,
    autoplay: true,
    navText: ["&lt;", ">"],
    smartSpeed: 1000,
    center: true,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };

  const showBanner = () => {
    let AddData = "";
    if (Blogdetails.uploadType === "0") {
      AddData = (
        <div className="blog_iframe">
          <iframe
            width="100%"
            height="100%"
            src={`https://www.youtube.com/embed/${Blogdetails.bannerVideo}`}
            title="Name Change Service in India | Name Change Agent in India"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen=""
          ></iframe>
        </div>
      );
    } else if (Blogdetails.uploadType === "1") {
      AddData = (
        <img
          src={Blogdetails.bannerImage}
          alt={Blogdetails.image_alt}
          title={Blogdetails.image_title}
          className="bannerimg"
        />
      );
    } else {
      AddData = (
        <div className="banner_desc">
          <div className="BannerDsceBox">
            <h2
              dangerouslySetInnerHTML={{
                __html: Blogdetails.bannerDesc,
              }}
            ></h2>
          </div>
        </div>
      );
    }
    return AddData;
  };

  return (
    <>
      <Helmet>
        <title>{Blogdetails.meta_title}</title>
        <meta name="description" content={Blogdetails.meta_description} />
        <meta name="keywords" content={Blogdetails.meta_keyword} />
        <link rel="canonical" href={window.location.href} />

        {Blogdetails.custom_script && (
          <script type="application/ld+json">
            {Blogdetails.custom_script}
          </script>
        )}

        {Blogdetails.custom_script2 && (
          <script type="application/ld+json">
            {Blogdetails.custom_script2}
          </script>
        )}
      </Helmet>

      <section className="PageBanner_section">{showBanner()}</section>

      <section className="section_padding blogDetails_section section_padding_bottom">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 ml-2 BlogTopForm">
              <div className="site_bar_form">
                <h4 className="form_heading">GET IN TOUCH</h4>
                <div className="siteForm" id="siteForm">
                  <iframe
                    className="ifram_form_id"
                    width="100%"
                    height="450"
                    src="https://manage.yourdoorstep.co/forms/ticket"
                    frameBorder="0"
                    allowFullScreen
                    title="form"
                  ></iframe>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="blog_content">
                <article id="post-87" className="single single-post">
                  <div className="post-header">
                    <p className="post-categories">{Blogdetails.category}</p>
                    <h1 className="post-title">{Blogdetails.title}</h1>
                    <div className="post-meta">
                      <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12 mt-3">
                          {/* <span className="post-meta-date">
                            {Blogdetails.date}
                          </span> */}
                          <span className="post-meta-date">
                            <i
                              className="fa fa-user bogdetailsicon"
                              aria-hidden="true"
                            ></i>
                            {Blogdetails.author}
                          </span>

                          <span className="post-meta-date blogdetailsDate">
                            <i
                              className="fa fa-calendar bogdetailsicon"
                              aria-hidden="true"
                            ></i>
                            {Blogdetails.date}
                          </span>

                        </div>
                        <div className="col-lg-6 col-md-12 Post_comm_shere mt-3">
                          <span className="post-comments">
                            <span className="fa fw fa-comment"></span>12
                            <span className="resp"> Comments</span>
                          </span>
                          <div className="share-post">
                            <Link
                              className="fb_share"
                              onClick={() => {
                                window.open(
                                  "https://www.facebook.com/sharer/",
                                  "Share This Post",
                                  "width=640,height=450"
                                );
                              }}
                              title="facebook"
                            >
                              <i
                                className="fa fa-facebook"
                                aria-hidden="true"
                              ></i>
                            </Link>
                            <Link
                              className="fb_twitter"
                              onClick={() => {
                                window.open(
                                  "https://twitter.com/",
                                  "Share This Post",
                                  "width=640,height=450"
                                );
                              }}
                              title="Twitter"
                            >
                              <i
                                className="fa fa-twitter"
                                aria-hidden="true"
                              ></i>
                            </Link>
                            <Link
                              to={
                                Catlang === "en"
                                  ? `https://api.whatsapp.com/send?text=${slug} - ${webSetting.baseURl}${slug}`
                                  : `https://api.whatsapp.com/send?text=${slug} - ${webSetting.baseURl}${Catlang}/${slug}`
                              }
                              className="fb_whatsapp"
                              title="Whatsapp"
                              rel="nofollow noopener"
                              target="_blank"
                            >
                              <i
                                className="fa fa-whatsapp"
                                aria-hidden="true"
                              ></i>
                            </Link>

                            <Link
                              className="fb_linkedin"
                              onClick={() => {
                                window.open(
                                  "http://www.linkedin.com/shareArticle/",
                                  "Share This Post",
                                  "width=640,height=450"
                                );
                              }}
                              title="linkedin"
                            >
                              <i
                                className="fa fa-linkedin"
                                aria-hidden="true"
                              ></i>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="postflex">
                    <div className="post-inner">
                      <div
                        className="post-content entry-content"
                        dangerouslySetInnerHTML={{
                          __html: Blogdetails.description,
                        }}
                      ></div>
                    </div>
                  </div>
                </article>
              </div>
            </div>
            <div className="col-lg-4 ml-2 BlogSiteForm">
              <div className="site_bar_form">
                <h4 className="form_heading">GET IN TOUCH</h4>
                <div className="siteForm" id="siteForm">
                  <iframe
                    className="ifram_form_id"
                    width="100%"
                    height="450"
                    src="https://manage.yourdoorstep.co/forms/ticket"
                    frameBorder="0"
                    allowFullScreen
                    title="form"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Why Choose Us Section Start */}
      <section className="whyChoose_section section_padding service_bg section_padding_bottom section_margin_top">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <hr className="before_border devider_border_color" />
            </div>
            <div className="col-md-6">
              <div className="section_title">
                <h2 className="title text-light">
                  {webSetting.whyChoose_title}
                </h2>
              </div>
            </div>
            <div className="col-md-3">
              <hr className="before_border devider_border_color" />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-4 col-sm-6 col-xs-6 mt-3">
              <div className="head_img">
                <img
                  src={whyChoosedata.stap1_img}
                  className="whychoose_img"
                  alt="img"
                />
              </div>
              <div className="choose_one">
                <ul className="list-unstyled">
                  <li>
                    <span className="red">01</span>
                  </li>
                  <li>
                    <h4>{whyChoosedata.stap1_title}</h4>
                  </li>
                  <li>
                    <p>{whyChoosedata.stap1_tagline}</p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-xs-6 mt-3">
              <div className="head_img">
                <img
                  src={whyChoosedata.stap2_img}
                  className="whychoose_img mr_tp"
                  alt="img"
                />
              </div>
              <div className="choose_one">
                <ul className="list-unstyled">
                  <li>
                    <span className="red">02</span>
                  </li>
                  <li>
                    <h4>{whyChoosedata.stap2_title}</h4>
                  </li>
                  <li>
                    <p>{whyChoosedata.stap2_tagline}</p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-xs-6 mt-3">
              <div className="head_img">
                <img
                  src={whyChoosedata.stap3_img}
                  className="whychoose_img"
                  alt="img"
                />
              </div>
              <div className="choose_one">
                <ul className="list-unstyled">
                  <li>
                    <span className="red">03</span>
                  </li>
                  <li>
                    <h4>{whyChoosedata.stap3_title}</h4>
                  </li>
                  <li>
                    <p>{whyChoosedata.stap3_tagline}</p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-xs-6 mt-3">
              <div className="head_img">
                <img
                  src={whyChoosedata.stap4_img}
                  className="whychoose_img"
                  alt="img"
                />
              </div>
              <div className="choose_one">
                <ul className="list-unstyled">
                  <li>
                    <span className="red">04</span>
                  </li>
                  <li>
                    <h4>{whyChoosedata.stap4_title}</h4>
                  </li>
                  <li>
                    <p>{whyChoosedata.stap4_tagline}</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Why Choose Us Section End */}
      {/* Content-5 Section Start */}

      {Blogdetails.description2 && (
        <DataContent desc={Blogdetails.description2} />
      )}

      {/* Content-5 Section End */}

      {/* Client Section Start */}
      <section id="client_section" className="section_padding client_section1">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <hr className="before_border " />
            </div>
            <div className="col-md-4">
              <div className="section_title">
                <h2 className="title text-dark">Clients Had To Say</h2>
              </div>
            </div>
            <div className="col-md-4">
              <hr className="before_border " />
            </div>
          </div>
          <div className="slider1">
            <OwlCarousel className="owl-theme" {...ClientDataSlider}>
              {clientdata.map((value, index) => (
                <div key={index} className="slider-card1 item">
                  <div className="d-flex justify-content-center align-items-center mb-4">
                    <img src={value.image} className="imgs" alt="testo1" />
                  </div>
                  <div className="nameedu1">
                    <h3 className="text-center">{value.name}</h3>
                    <p className="text-center">{value.designation}</p>
                  </div>
                  <p className="clientwords1">{value.description}</p>
                </div>
              ))}
            </OwlCarousel>
          </div>
        </div>
      </section>
      {/* Client Section End */}

      {/* Blog section Start */}
      <section className="blog_section  section_padding_bottom">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <hr className="before_border" />
            </div>
            <div className="col-md-2">
              <div className="section_title">
                <h2 className="title text-dark">Blogs</h2>
              </div>
            </div>
            <div className="col-md-5">
              <hr className="before_border" />
            </div>
          </div>
          <div className="row">
            {Blogs.map((value, index) => (
              <BlogHomebox
                key={index}
                slug={value.slug}
                lang={value.lang}
                image={value.sort_img}
                image_title={value.image_title}
                image_alt={value.image_alt}
                date={value.date}
                title={value.title}
                tagline={value.tagline}
              />
            ))}
          </div>
        </div>
      </section>
      {/* Blog section End */}
    </>
  );
}
